// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-our-team-js": () => import("./../../../src/pages/about/our-team.js" /* webpackChunkName: "component---src-pages-about-our-team-js" */),
  "component---src-pages-about-philosophy-goals-js": () => import("./../../../src/pages/about/philosophy-goals.js" /* webpackChunkName: "component---src-pages-about-philosophy-goals-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-early-years-js": () => import("./../../../src/pages/early-years.js" /* webpackChunkName: "component---src-pages-early-years-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kindergarten-readiness-js": () => import("./../../../src/pages/kindergarten-readiness.js" /* webpackChunkName: "component---src-pages-kindergarten-readiness-js" */),
  "component---src-pages-parents-index-js": () => import("./../../../src/pages/parents/index.js" /* webpackChunkName: "component---src-pages-parents-index-js" */),
  "component---src-pages-preschool-js": () => import("./../../../src/pages/preschool.js" /* webpackChunkName: "component---src-pages-preschool-js" */),
  "component---src-pages-young-toddler-js": () => import("./../../../src/pages/young-toddler.js" /* webpackChunkName: "component---src-pages-young-toddler-js" */)
}

